html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
  overscroll-behavior: none;
}

body.admin_body {
  overflow: hidden;
}

@media (max-width: 768px) {
  body.admin_body {
    overflow: scroll;
  }
}

* {
  font-family: 'Nunito Sans', sans-serif;
  /* font-family: 'Oswald', sans-serif; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --text-main-color: #2b3940;
}

.justify-content-space-between {
  justify-content: space-between;
}

p {
  font-size: 16px;
}

.brand-logo img,
.footer-logo {
  max-height: 32px;
  max-width: 110px;
}

.connect_btn {
  min-width: 60px;
}

/* Common */
.page___heading {
  display: none;
}

button.btn.action_btn {
  min-width: 30px;
}

/* pagination */
.pagination {
  justify-content: center;
}

.pagination a.page-link.active {
  background: #aaa;
  color: #fff;
}

/* Admin Table */
.bg-white.shadow-8.datatable_div.rounded {
  padding: 0 !important;
}

.table.main_data_table th,
.table.main_data_table td {
  border: unset;
  vertical-align: middle;
}

.table-striped tbody {
  background-color: #f1f1f145;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eee;
}

.main_data_table {
  border: none;
  border-radius: 0;
  overflow: hidden;
  border-collapse: collapse;
}

.main_data_table thead {
  background-color: #fcb6b6;
}

.main_data_table thead th,
.main_data_table thead a.text-gray {
  color: #333 !important;
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 1.25;
}

.dashboard-main-container>.container-fluid {
  margin: 0;
  width: calc(100%);
  border-radius: 10px;
  height: 100vh;
  overflow: auto;
  /* background: #fff; */
  padding-bottom: 10px;
  /* height: calc(100vh - 46px);
  overflow: hidden; */
}

.table-responsive {
  padding-left: 0;
  padding-right: 0;
}

.main_data_table br {
  display: none;
}

.main_data_table_inn thead tr {
  background: #fff;
}

.main_data_table_inn thead th,
.main_data_table_inn thead a.text-gray {
  color: #333 !important;
}

.main_data_table tbody tr {
  background: #fff;
  /* border-top: 4px solid #f4f5f8; */
  border-bottom: 4px solid #f4f5f8;
  margin: 0;
  border-spacing: 0px;
}

.main_data_table tbody tr.applicant_row.applicant_row_status td {
  padding-top: 0 !important;
}

.main_data_table {
  border: none;
  border-radius: 0;
  overflow: hidden;
  border-collapse: collapse;
  border-spacing: 0px;
  color: #333;
}

button.btn.action_btn {
  min-width: 30px;
  line-height: normal;
  height: auto;
}

.table.main_data_table th,
.table.main_data_table td {
  padding: 4px 7px !important;
  line-height: 1;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

table.table.table-striped.main_data_table_inn tr {
  background: #f1f1f1;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
}

.bg-white.shadow-8.datatable_div {
  background: transparent !important;
  box-shadow: none;
  padding: 0 !important;
}

.main_data_table .response_main_div .container table {
  margin: 0;
}

.main_data_table .response_main_div .container {
  padding: 0;
  margin-top: 15px;
}

.dashboard-main-container .form-control {
  height: 2rem;
  padding: 3px 5px !important;
  font-size: 14px;
}

.dashboard-main-container .new_folder_create .form-control {
  height: 34px;
}

.dashboard-main-container .form_group .input_label {
  position: unset;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.btn-group.button_group {
  /* flex-wrap: wrap; */
  /* max-width: 160px; */
  /* gap: 5px 0; */
}

.back_btn_profile {
  position: relative;
  top: 10px !important;
  left: 30px !important
}

.applicant_row,
.job_row {
  position: relative;
}

span.featured_tag {
  position: absolute;
  top: 0;
  left: 10%;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 36px;
  line-height: 1;
  height: 100%;
  opacity: .1;
  color: #fa5f1c !important;
  text-transform: uppercase;
  font-weight: 900;
  background: transparent !important;
}

span.web_tag {
  position: absolute;
  bottom: -2px;
  left: 0;
  padding: 1px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

/* SIDEBAR */

ul.list-unstyled.dashboard-layout-sidebar {
  max-height: calc(100vh - 47px);
  overflow-y: auto;
}

/* header */
.main-menu>li>.nav-link {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

header.site-header.admin_header {
  /* max-width: calc(100% - 155px);
  right: 0;
  left: auto;
  margin: 1px 20px 5px; */
  max-width: calc(100% - 0px);
  /*To show full width header*/
  right: 0;
  left: auto;
  margin: 0;
  border-radius: 0;
  background: #992b32;
  top: 0;
  padding: 3px 5px 2px;
}

header.site-header.admin_header h3 {
  color: #fff;
}

/* Drop-down css */
.gr-menu-dropdown {
  position: absolute;
  min-width: 227px;
  max-width: 227px;
  box-shadow: 0 52px 54px rgb(65 62 101 / 30%);
  border-radius: 8px;
  border: 1px solid var(--border-color-3);
  padding: 15px 0px;
  top: 100%;
  z-index: -99;
  opacity: 0;
  transition: opacity .4s, top .4s;
  pointer-events: none;
  left: 0;
  right: auto;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--border-color-3);
  background-color: #fff;
  display: block;
  border-top: 3px solid #00b074;
}

.show-gr-dropdown .gr-menu-dropdown.dropdown-right {
  right: -12px;
  left: unset;
  top: 100%;
}

/* Border Radius css */

.rounded-70 {
  border-radius: 70px;
}

/* JOB Search form */
.filter-search-form-2 .filter-inputs .nice-select {
  outline: none;
}

.job_search_main_form {
  max-width: 1024px;
  margin: 0 auto;
}

.hero_container {
  max-width: 1024px;
}

.bg-home-banner {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url(https://images.pexels.com/photos/8297452/pexels-photo-8297452.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-position: center 42%;
  background-repeat: no-repeat;
  background-size: cover;
}

.filter-search-form-2 .filter-inputs .form-group {
  width: 49%;
}

/* JOB FILTER BOX */
.job_filter_card_home {
  min-width: 50%;
  max-width: 100%;
}

.job_filter_card_home .card-body {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1;
  height: 95px;
}

.job_filter_block .btn.job_filter_btn {
  min-width: auto;
  font-weight: 400;
  height: 22px;
  color: var(--text-main-color);
  border-color: var(--text-main-color);
  padding: 3px 5px;
  font-size: 12px;
}

.filter-search-form-2 .css-13cymwt-control {
  min-height: 40px;
  border: none;
  background: transparent;
}

.job_filter_block {
  display: flex;
  max-width: 1024px;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* margin: 0 auto !important;
  gap: 2%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -14%;
  max-width: 1024px;
  z-index: -1; */
}

.job_filter_block .card.job_filter_card {
  max-width: 49%;
  box-shadow: 0 40px 60px rgb(0 0 0 / 25%);
}

@media (max-width: 992px) {
  .job_filter_block {
    position: relative;
    gap: 15px;
    justify-content: center;
  }

  .edit_profile_icon span {
    position: absolute;
    bottom: 0;
  }

  .edit_profile_icon {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .job_filter_block {
    flex-direction: column;
    max-width: 100%;
  }

  .job_filter_block .card.job_filter_card,
  .filter-search-form-2 .filter-inputs .form-group {
    max-width: 100%;
  }

  .filter-search-form-2 .filter-inputs .form-group {
    width: 100%;
  }

  .job_search_main_form {
    padding: 10px !important;
    border-radius: 10px;
    margin-top: 36px;
  }
}

/* Job Box */
.job_box {
  max-width: 1024px;
}

.row.job_header {
  justify-content: space-between;
}

.company_box {
  /* max-width: 85%; */
}

.company_box .company_logo {
  /* max-height: 50px; */
  margin-right: 10px;
  max-width: 150px;
}

span.job_swap_label {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  font-weight: 600;
  background: #00b074;
  padding: 3px 5px 3px 10px;
  border-radius: 0 0.5rem 0 0.5rem;
  color: #fff;
}

@media (min-width: 768px) {}

/* CATEGORY BOX */
.category_box a {
  position: relative;
}

.category_box .category_text_box p {
  position: absolute;
  top: 50px;
  right: 50px;
  text-transform: lowercase;
  margin: 0;
  max-width: 80px;
  line-height: 1;
  text-align: right;
  font-size: 12px;
}

.category_box .category_text_box p span {
  font-size: 40px;
}

/* ALL FORM INPUT */
.form-group,
.form_control {
  position: relative;
  padding: 0 15px !important;
}

.task_dashboard_form .form-group,
.task_dashboard_form .form_control {
  padding: 0 5px !important;
}

.form-group label,
.form_control .input_label {
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  position: absolute;
  top: -7px;
  z-index: 1;
  left: 26px;
  background: #fff;
  padding: 0 5px;
}

.task_dashboard_form .form-group label,
.task_dashboard_form .form_control .input_label {
  left: 12px;
}

.form_control .input_label {
  background: transparent;
  top: -17px;
  left: 15px;
  text-transform: capitalize !important;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

input[type="file" i].form-control {
  padding: 6px;
}

/* PROFILE PAGE */
.personal_info_box {
  gap: 0;
}

.user_status_btn button.dropdown-toggle {
  height: 20px;
  min-width: unset;
  line-height: 20px;
  width: 100%;
  color: #fff;
  font-weight: 400;
}

.user_status_btn {
  padding: 0;

}

label.image_upload_btn {
  position: absolute;
  bottom: -10px;
  left: 40px;
  background: #ccc;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}

label.image_upload_btn_2 {
  position: absolute;
  bottom: -8px;
  right: 0;
  left: 0;
  background: #333;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/* CKEDITOR */
.cke_chrome {
  border: none !important;
}

/* LOGIN MODAL */
.login-modal-main .dark-mode-texts {
  background-image: url(http://localhost:3000/image/favicon.png);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

/* Add opactiy in dropdown */
@media (max-width: 992px) {
  .custom-opacity {
    opacity: 1 !important;
  }
}

.response_filters .form-control {
  height: 2rem;
  padding: 5px 10px;
  font-size: 12px;
}

.response_filters .input_label {
  font-size: 12px;
}

/* DASHBOARD */
.dashboard_card {
  overflow: hidden;
  /* min-height: 100%; */
  /* box-shadow: 6px 2px 8px #ccc; */
  padding-bottom: 15px;
}

.dashboard_card .dashboard_select {
  padding: 0 5px;
}

.dashboard_card .datatable_div {
  box-shadow: none;
  border-radius: 0 !important;
}

/* EMPLOYEE TABLE */
.d-flex.profile_box {
  gap: 7px;
  align-items: center !important;
}

.d-flex.executive_box {
  gap: 7px;
  align-items: center !important;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}

.table-responsive.main_table_div {
  position: relative;
  min-height: 150px;
}

.dashboard_card .table-responsive.main_table_div {
  position: relative;
  min-height: unset;
}

.dashboard_card .mb-18,
.dashboard_card .mb-8 {
  margin-bottom: 0 !important;
}

.custom_loader {
  position: absolute;
  height: 100%;
  width: 100%;
  ALIGN-ITEMS: center !important;
  background: #fff;
  z-index: 99;
  top: 0;
  left: 0;
}

.custom_loader-01 {
  position: absolute;
  height: 100%;
  width: 100%;
  ALIGN-ITEMS: center !important;
  background: #fff;
  z-index: 1000;
  top: 0;
  left: 0;
}

.coustam_datepicker {
  position: relative;
  overflow: hidden;
}

input.coustam_datepicker[type="date"]::-webkit-calendar-picker-indicator {

  /* //-webkit-appearance: none; */
  width: 100%;
  opacity: 0;
  height: 100px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  position: absolute;
}

.container.document_container.bg-white.p-7 {
  height: calc(100vh - 98px);
  overflow-x: hidden;
}

.doc_preview_box {
  height: calc(100vh - 190px);
  width: 100%;
  overflow-y: auto;
}

.btn.doc_btn {
  width: auto;
  min-width: auto;
  height: auto;
  line-height: 1;
  display: block;
  margin: 5px;
  font-size: 12px;
  font-weight: 400;
}

.comments_and_replies {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.SkinnyRail__Wrapper___uuKuh {
  display: none;
}

#applieddocuments .document_container.bg-white {
  max-height: calc(100vh - 127px);
}

.list-group {
  max-height: calc(100vh - 140px);
  overflow: visible;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}

ul.list-group li.list-group-item {
  padding: 4px 7px;
}

.dash_card.card h3.heading {
  color: #4751fa;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0px;
  font-size: 30px;
}

span.dash_statistic {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 90px;
  font-weight: 900;
  line-height: 1;
  color: #ff8c8e;
}

.row.doc_upload_row select.form-control.form-select {
  height: 40px;
  padding: 5px 10px;
}

select.form-control.select_document_type {
  text-transform: capitalize;
}

.list-group .list-group-item .verified_doc {
  max-width: 50px;
  float: right;
  padding: 2px;
  background-color: #fff;
}

.verified_doc_img {
  height: 18px;
  width: auto;
}

.doc_action_div {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 10;
}

.doc_upload_col button.btn {
  min-width: unset;
  height: auto;
  line-height: 1;
}

.row.doc_upload_row {
  gap: 15px;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step {
  font-size: 12px;
  padding: 3px 5px 3px 16px;
  min-width: auto;
  margin-right: 0px;
  line-height: 1.5;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step:after,
.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step:before {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #d9e3f7;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step:after,
.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step:before {
  right: -12px;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step:before {
  border-left: 12px solid #fff;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step.current:after {
  border-left: 12px solid #fa474a;
}

.arrow-wrapper.custome_arrow_wrapper .arrow-steps .step.visa_current:after {
  border-left: 12px solid #4743db;
}

.absolute_top_right {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 767px) {
  header.site-header {
    max-width: 100%;
    left: 0;
    margin: 0;
    /* padding: 15px 0 !important; */
  }

  nav.navbar.site-navbar.offcanvas-active.navbar-expand-lg {
    justify-content: space-between;
    align-items: center;
  }

  button.main_search_button_home {
    width: 100%;
  }

  .media.align-items-center.company_box {
    flex-wrap: wrap;
    gap: 15px;
  }

  .main_job_box_ button.btn.btn-secondary {
    width: 100%;
  }

  .main_job_box_ button.btn.btn-info {
    width: 100%;
  }

  .job_search_box_page.translateY-25 {
    transform: none;
  }

  .search-filter.job_search_filter {
    display: block !important;
  }

  .search-filter.job_search_filter select {
    width: 100%;
  }

  .px-11.pt-11.pb-7.login_Modal_box {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .container,
  .container-xs,
  .container-sm {
    max-width: 100%;
  }

  .col.p-1.form_group.mb-5.mt-4 {
    min-width: 50%;
    margin-bottom: 0 !important;
  }

  header.site-header.admin_header {
    max-width: 100%;
    left: 0;
    right: 0;
    margin: 0;
  }

  .dashboard-main-container>.container {
    margin: 0;
    width: 100%;
  }
}

/* Breadcrups CSS */

.arrow-wrapper {
  display: table-cell;
  height: auto;
  vertical-align: middle;
}


.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 8px 8px 8px 25px;
  min-width: 129px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -12px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

/* .arrow-steps .step:first-child:before {
  border: none;
} */

/* .arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
} */

/* .arrow-steps .step span {
  position: relative;
} */

/* .arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
} */

/* .arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
} */
.arrow-steps .step.approved {
  color: #fff;
  background-color: #0b795d;
}

.arrow-steps .step.approved:after {
  border-left: 13px solid #0b795d !important;
}

.arrow-steps .step.pending {
  color: #fff;
  background-color: #f57a08;
}

.arrow-steps .step.pending:after {
  border-left: 13px solid #f57a08 !important;
}

.arrow-steps .step.reject {
  color: #fff;
  background-color: #fa474a;
}

.arrow-steps .step.reject:after {
  border-left: 13px solid #fa474a !important;
}

.arrow-steps .step.visa_current {
  color: #fff;
  background-color: #4743db;
}

.arrow-steps .step.visa_current:after {
  border-left: 17px solid #4743db;
}

.arrow-steps .step.sub-stage {
  color: #fff;
  background-color: #ed591e;
}

.arrow-steps .step.sub-stage:after {
  border-left: 17px solid #ed591e;
}

@media (max-width: 568px) {
  .doc_upload_row .btn {
    min-width: 118px;
  }

  .media_profile_box {
    text-align-last: start;
  }
}

.dropdown-menu.show {
  min-width: 100px;
}

.dropdown-menu .dropdown-item {
  padding: 0.25rem 0.5rem;
}

.accordian_btn_design button {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px;
}

.accordian_btn_design button:hover,
.accordian_btn_design button:focus {
  border: none;
  outline: none;
  background-color: #fcb6b6;
}

/* src/PreviewEmail.css */
.preview-email {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  padding: 20px;
}

.header img {
  max-width: 30%;
  height: auto;
}

.content {
  padding: 20px;
}

.cta-button {
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #007BFF;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

/* Email preview css */
/* src/PreviewEmail.css */
.gmail-preview-container {
  /* background-color: #f9f9f9; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  height: auto;

}

.email-content {
  /* padding: 30px; */
  border-radius: 10px;
  position: absolute;
  height: 100%;
  width: 100%;
  ALIGN-ITEMS: center !important;
  background: #fff;
  z-index: 99;
  top: 0;
  left: 0;
}

.email-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.email-header p {
  font-size: 16px;
  margin: 0;
}

.email-body p {
  font-size: 16px;
  margin: 0;
  line-height: 1.6;
}

.attachments {
  display: inline-flex;
  background-color: white;
}

.attachments img {
  margin-top: 10px;
  max-width: 100%;

}

.download-icon {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  /* border-radius: 50%; */
  padding: 5px;
  transition: opacity 0.3s;
  display: none;
  text-decoration: none;
}

.download-icon a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.download-icon svg {
  width: 30px;
  height: 25px;
}

.htmlFileCls {
  width: "85px";
  color: "#333";
  position: "relative";
}

.htmlFileCls:hover .download-icon {
  opacity: 1;
  display: block;
}

.education_btn_grp {
  position: absolute;
  display: block;
  left: 100%;
}


/* DOCUMENT AND COMMENT DESIGN */
.document_container .doc_list {
  /* height: calc(100vh - 400px); */
}

.comment_box_card {
  position: relative;
}

/* .comment_box_card .comment_status_update {
  position: absolute;
  top: 4px;
  right: 4px;
  /* width: 24px;
  height: 24px; */
/* line-height: 24px; */
/* } */

.highlighted-comment.comment_box_card {
  border: 2px solid blue;
  background-color: #f5f5f5 !important;
  z-index: 99;
}

.reply_box .comment-input {
  width: calc(100% - 26px);
}



.reply_box_container {
  transition: all .3s ease;
}

div#pg-photo-container img {
  width: 100% !important;
  height: auto !important;
}

.pg-viewer-wrapper .photo-viewer-container {
  height: max-content !important;
}

.braintreePayButton {
  margin-left: auto;
  display: flex;
}

#braintree-drop-in-div {
  min-height: 150px;
  margin-top: 5px;
  margin-bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.global_search_content.position-fixed {
  top: 0;
  right: 0;
  background-color: rgb(0 0 0 / 25%);
  height: 100vh;
  max-width: 100%;
  width: 0;
  transition: width .5s;
  z-index: -1;
  overflow: hidden;
}

.global_search_content.position-fixed.show {
  transition: width .5s;
  width: 100%;
  z-index: 9999;
  overflow-y: auto;

}

.global_search_content.position-fixed .left_side {
  max-width: calc(100% - 900px);
  width: 100%;
}

.global_search_content.position-fixed .right_side {
  max-width: 900px;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0 4px #333;
}

.notification_box.global_search_content.position-fixed .left_side {
  max-width: calc(100% - 500px);
}

.notification_box.global_search_content.position-fixed .right_side {
  max-width: 500px;
}

.global_search_content.position-fixed .right_side .global_search_result {
  height: calc(100vh - 64px);
  overflow: auto;
}

.global_search_content.position-fixed .right_side .global_search_result.notofications_list {
  height: calc(100vh);
}

.notification_count {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px;
  border-radius: 50%;
}

.doc_name_td span {
  line-break: anywhere;
}

.email_container,
.emails .email-list {
  height: calc(100vh - 140px);
}

.table-responsive.email_list_table {
  height: calc(100vh - 200px);
  overflow: auto;
}

.email-content .email-body {
  height: calc(100vh - 268px);
  overflow: auto;
}

.accordian_btn_design {
  margin-bottom: 1px;
  border-radius: 0 !important;
}

.accordian_btn_design button {
  text-align: left;
  border-radius: 0 !important;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 1;
  /* color: #fff; */

}

.table th,
.table td {
  vertical-align: middle;
}

.admin_login_page {
  min-height: 350px;
  height: 100vh;
}

.chat_box_container .chat-container {
  height: calc(100vh - 140px);
  overflow: auto;
}

/* Agent conversation chat design */
/* Chat container */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Chat messages container */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

/* Individual message */
.message {
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
  display: flex;
}

.message.received {
  justify-content: end;
}

.message.send {
  justify-content: start;
}

.message.received .message-content {
  max-width: 70%;
  width: 100%;
}

/* Message text */
.message-text {
  background-color: #fff;
  margin: 0;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 2px;
  font-size: 14px;
  box-shadow: 0 0 4px #ccc;
}

/* Message info (sender and time) */
.message-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 0 8px;
  flex-direction: row-reverse;
}

/* Message sender */
.message-sender {
  margin: 0;
  font-weight: bold;
}

/* Message time */
.message-time {
  margin: 0;
  color: #666;
}

.chat_box_container .message_input_box {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  height: 100%;
}

.chat_box_container .chat-input {
  display: flex;
  gap: 3px;
}

.chat_box_container .text_msg_control {
  display: flex;
  flex-direction: column;
  max-width: 130px;
  gap: 2px;
}

.chat_box_container .text_msg_control .message_input {
  font-size: 12px;
  line-height: 1;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #333;
}

.chat_box_container .send-button.btn.btn-primary {
  width: auto;
  min-width: 65px;
  height: auto;
  min-height: auto;
}

.chat_box_container .send-button.btn.btn-primary i {
  /* transform: rotate(45deg); */
  font-size: 30px;
}

li.placeholder {
  /* display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: .5;
  color: #000;
  width: 75px; */
  /* color: transparent !important; 
  text-shadow: 0 0 6px #333; */
}

/* li.placeholder span {
  color: transparent !important;
  text-shadow: 0 0 5px #828282;
  font-weight: 400;
}

li.placeholder span svg {
  color: #333 !important;
} */

/* USER TIMELINE CSS*/
.timeline_box {
  height: calc(100vh - 150px);
  overflow: auto;
}

.timeline {
  width: 85%;

  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 32px 0 32px 32px;
  border-left: 2px solid #ff9294;
}

.timeline-item {
  display: flex;
  gap: 15px;
  align-items: center;
}

.timeline-item+* {
  margin-top: 15px;
}

.timeline-item+.extra-space {
  margin-top: 48px;
}


.timeline-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: -39px;
  flex-shrink: 0;
  overflow: hidden;
  box-shadow: 0 0 0 4px #fa474a;
}

.timeline-item-icon svg {
  width: 20px;
  height: 20px;
}

.timeline-item-icon.faded-icon {
  background-color: #fff;
  color: #aaa;
}

.timeline-item-icon.filled-icon {
  background-color: blue;
  color: #fff;
}

.timeline-item-description {
  display: flex;
  gap: 8px;
  color: #aaa;
}

.timeline-item-description img {
  flex-shrink: 0;
}

.timeline-item-description a {
  color: #ccc;
  font-weight: 500;
  text-decoration: none;
}

.timeline-item-description a:hover,
.timeline-item-description a:focus {
  outline: 0;
  color: blue;
}

.public-DraftStyleDefault-block {
  margin: 0 !important
}

.intervire-msg::first-letter {
  text-transform: uppercase;
}

/* Folder design */
.file-list {
  display: flex;
  flex-wrap: wrap;
  /* gap: 15px; */
}

.file-list .file-item {
  width: 130px;
  /* Adjust width as needed */
  /* background-color: #f7f7f7; */
  border-radius: 10px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
}

.file-background {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.folder-icon,
.word-icon,
.pdf-icon,
.file-icon {
  /* margin-right: 10px; */
  /* Adjust margins as needed */
}

.file-icon {
  max-width: 100px;
  max-height: 100px;
  /* Adjust max-width and max-height as needed */
  /* border-radius: 50%; */
}

.file-content {
  text-align: center;
}

.file-count {
  margin-bottom: 5px;
  font-weight: bold;
}

.file-name {
  font-size: 13px;
  line-height: normal;
  /* margin-bottom: 5px; */
}

.modified-time {
  color: gray;
  font-size: 11px;
  font-style: italic;
}

.file-background:hover {
  background-color: #edebe9
}

/*Employer page martin top for user and company module*/
.employer-detail-top-padding {
  padding-top: 70px;
}

@media (max-width: 768px) {
  .employer-detail-top-padding {
    padding-top: 120px;
  }
}

@media (max-width: 320px) {
  .employer-detail-top-padding {
    padding-top: 140px;
  }
}

/*Employee page martin top for user and company module*/
.employee-detail-top-padding {
  padding-top: 26px;
}

@media (max-width: 768px) {
  .employee-detail-top-padding {
    padding-top: 65px;
  }
}

@media (max-width: 425px) {
  .employee-detail-top-padding {
    padding-top: 115px;
  }
}

@media (max-width: 320px) {
  .employee-detail-top-padding {
    padding-top: 140px;
  }
}

/* Chat input form */
/* .chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f4f4f4;
} */

/* Chat input field */
/* .message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
} */

/* Send button */
/* .send-button {
  background-color: #075E54;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
} */

/* Send button hover */
/*Adobe pdf css*/
#adobe-dc-view {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.msg-color span {
  color: rgb(0, 114, 238);
}

.dashboard-sidebar-wrapper {
  width: 125px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  /* background: var(--bg); */
  background: #992b32;
  transform: translateX(-100%);
  /* transition: .4s transform; */
  z-index: 800;
  transition: all .2s;
}

.dashboard-sidebar-wrapper.show~.dashboard-main-container {
  width: calc(100% - 125px);
  margin-left: 125px;
  transition: all .2s;
}

/*To set sidebar menu to show and hide on all screens*/
@media (display-mode:fullscreen) {
  .dashboard-sidebar-wrapper {
    transform: none;
  }
}

.dashboard-sidebar-wrapper.show {
  transform: translateX(0%);
}

/* src/components/AboutUs.css */
.about-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.about-section h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 18px;
  line-height: 1.8;
}

.about-section .mission-vision {
  margin-top: 40px;
}

.about-section .mission-vision h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.about-section .mission-vision p {
  font-size: 16px;
  margin-bottom: 15px;
}

.about-section img {
  margin-bottom: 20px;
}

.about-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-section .row.mt-5 {
  margin-top: 60px;
}

.about-section .img-fluid {
  max-width: 100%;
  height: auto;
}

.about-section .rounded-circle {
  border-radius: 50%;
}

.about-section .mb-3 {
  margin-bottom: 15px;
}

.new-job-box {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
}

.new-job-box:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.new-job-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.company-name {
  font-size: 1rem;
  color: #555;
}

.location-date {
  font-size: 0.875rem;
  color: #777;
}

.new-job-description {
  font-size: 0.875rem;
  color: #333;
}

@media (min-width :768px) {
  .card.new-job-box.my-3.p-3.light-mode-texts.bg-white.rounded.hover-shadow-3.hover-border-green {
    min-height: 424px;
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

@keyframes fadeOutAnimationKeyframes {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInAnimationKeyframes {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes moveUpAnimationKeyframes {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-0px);
  }
}

@keyframes moveDownAnimationKeyframes {
  from {
    transform: translateY(-0px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes embeddedViewJobMoveUpAnimationKeyframes {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: -15px
  }
}

@keyframes jobTitleMoveUpAnimationKeyframes {
  from {
    transform: scale(1) translateY(0px);
    transform-origin: 0 0;
  }

  to {
    transform: scale(0.9) translateY(-0px);
    transform-origin: 0 0;
  }
}

@keyframes jobTitleMoveDownAnimationKeyframes {
  from {
    transform: scale(0.9) translateY(-0px);
    transform-origin: 0 0;
  }

  to {
    transform: scale(1) translateY(0px);
    transform-origin: 0 0;
  }
}

/* General Layout */
.jobsearch-RightPane {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#jobsearch-ViewjobPaneWrapper {
  outline: none;
}

button.visible-on-focus-only {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
}

h2.css-1h510g5 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

/* Close Button */
button.jobsearch-ClosableViewJobPage-button-close {
  background-color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50px;
  z-index: 1;
}

.jobsearch-JobComponent {
  margin-bottom: 20px;
}

/* Header Image and Logo */
.jobsearch-JobInfoHeader-headerImageContainerEji {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.jobsearch-JobInfoHeader-headerImage {
  width: 450px;
  height: 85px;
  object-fit: cover;
  border-radius: 5px;
}

.jobsearch-JobInfoHeader-logo {
  position: absolute;
  top: 52px;
  left: 20px;
  border: 2px solid #fff;
  border-radius: 17%;
  width: 75px;
  height: 75px;
}

.jobsearch-JobInfoHeader-title-container {
  margin-top: 10px;
}

h2.jobsearch-JobInfoHeader-title {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.css-1b6omqv {
  font-weight: normal;
  color: #999;
}

.css-1qz04qx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.css-1unnuiz {
  display: flex;
  align-items: center;
}

.css-ppxtlp {
  font-size: 18px;
  color: #333;
  margin-right: 10px;
}

/* Company Information */
.css-1toufe4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}

.css-hon9z8 {
  font-size: 16px;
  color: #0073e6;
}

.css-waniwe {
  font-size: 14px;
  color: #777;
  margin-top: 10px;
}

.css-kyg8or {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}

.css-1x54ou6 {
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

/* Apply Button */
button.css-1234qe1 {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.css-1234qe1:hover {
  background-color: #218838;
}

/* Job Description */

h2.jobsearch-JobComponent-description {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}

/* p {
  line-height: 1.5;
  margin-bottom: 20px;
  color: #666;
}

ul {
  list-style: disc;
  padding-left: 20px;
  color: #666;
}

ul li {
  margin-bottom: 10px;
} */

/* Location Section */
#jobLocationSectionWrapper {
  margin-top: 30px;
}

#jobLocationWrapper {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
}

#jobLocationWrapper svg {
  margin-right: 10px;
}

h2.css-1yytfzy {
  font-size: 20px;
  margin-bottom: 15px;
}

/* Benefits Section */
#benefits {
  margin-top: 30px;
}

h2.css-gfuqm7 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.css-1opnqdm {
  font-size: 14px;
  color: #999;
}

ul.css-8tnble {
  margin-top: 10px;
}

ul.css-8tnble li {
  margin-bottom: 5px;
  font-size: 16px;
  color: #666;
}

/* Separator */
.role-separator {
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  margin: 20px 0;
}

/*Notes*/
.single_note {
  height: 95vh;
  overflow-y: scroll;
}

.single_note p {
  margin: 0;
}

/*Manage select box style for arrow at home page*/
/* Remove default appearance and hide the native arrow */

/* Add custom dropdown arrow */
.set-arrow select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.set-arrow::after {
  content: '\276F';
  position: absolute;
  color: black;
  top: 30%;
  right: 30px;
  /* z-index: 9999999; */
  font-family: fotawesome;
  transform: rotate(90deg);
  font-size: small;
  font-weight: bold;
  overflow: hidden;
}

.notification_text_msg * {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  color: #6c757d !important;
}

.study_home_banner {
  background-position: center 42%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  width: 100%;
  margin-top: -50px;
  font-size: 50px, ;
  display: flex;
  justify-content: center;
  align-items: center;
}